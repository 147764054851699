.recruit {

	.page-sub {
		margin-bottom: 0;
	}

	.title-page {
		&--recruit {
			background-image: url("../img/recruit/bg-title-page-recruit.jpg");
		}
	}

	.chatch {
		display: flex;
		flex-wrap: wrap;
		justify-content: left;
		align-items: center;
		text-align: left;
		color: #FFF;
		@include fs(34,14);
		font-weight: 200;
		background: url(../img/recruit/bg-recruit-01.jpg) no-repeat center center;
		background-size: cover;
		height: 450px;
		position: relative;
		margin: 0 0 40px;
		padding: 52px;

		@include media-xs {
			height: 155px;
			padding: 12px;
			margin: 0;
		}
	}

	&-section {
		@include media-xs {
			padding: 55px 0;
		}

		&__inner {
			@include defaultWidth;
			padding: 80px 0;
			@include media-xs {
				padding: 0 20px;
			}
			&--s {
				@include media-sm {
					width: 960px;
				}
			}
		}
		&--gray {
			background: #fafafa;
		}
	}

	.heading-md {

		&--seichou {
			&:before {
				background-color: #0083b3;
			}
		}

		&--com {
			&:before {
				background-color: #b38f00;
			}
		}

		&--job {
			&:before {
				background-color: #72b300;
			}
		}
	}

	.recruit-content {
		background: #fafafa;

		&__body {
			display: flex;
			justify-content: space-between;

			@include media-xs {
				display: block;
			}
		}

		&__pict {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 24px;
			width: 280px;
			height: 187px;
			position: relative;
			background: #fafafa;

			@include media-xs {
				margin-bottom: 16px;
				width: 100%;
				height: 130px;
			}
		}

		&__ph {

			img {
				transition: .3s;

				@include media-xs {
					height: 70px;
				}
			}
		}

		&__element {
			width: 280px;

			@include media-xs {
				width: 100%;
				margin-bottom: 40px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			a {
				display: block;
				color: $base-font-color;
				text-decoration: none;
				text-align: center;

				&:hover {
					@include media-sm {
						.index-service__ph img {
							transform: scale(1.08);
						}
					}
				}
			}

			p {
				@include fs(16,14);

				@include media-xs {
					margin: 0;
				}
			}
		}

		&__title {
			font-weight: bold;
			@include fs(18,16);
			margin-bottom: 14px;
			color: #0083b3;

			@include media-xs {
				margin-bottom: 12px;
			}
		}

		&__link {
			border-top: 1px solid $base-border-color;
			text-align: right;
			padding-top: 12px;

			span {
				display: inline-block;
				@include fs(12,12);
				position: relative;
				padding-right: 30px;

				&::after {
					content: "";
					display: block;
					background: url("../img/icon/icon-ar-right-black.png") no-repeat;
					@include bgs(20px,5px);
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -3px;
				}
			}
		}
	}

	.recruit-section {
		&--com {

			.heading-recruit {

				&::after {
					border-bottom: solid 1px #b38f00;
				}
			}

			.recruit-content {

				&__title {
					color: #b38f00;
				}
			}
		}

		&--job {

			.heading-recruit {

				&::after {
					border-bottom: solid 1px #72b300;
				}
			}

			.recruit-content {

				&__title {
					color: #72b300;
				}
			}
		}
	}

	&-button {
		background: #039;

		&-block {
			text-align: center;
		}

		&:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background: #333;
			left: 0;
			top: 0;
			-webkit-transform: scale(0, 1);
			transform: scale(0, 1);
			-webkit-transform-origin: left center;
			transform-origin: left center
		}
		&--large {
			width: 600px;
			@include media-xs {
				width: 100%;
			}
			padding: 24px 0;
			color: #fff;
			border: none;
			@include fs(20,
				14);
			margin: auto;
		}

		&--large span:after {
			content: "";
			display: block;
			background: url(../img/icon/icon-ar-right-white.png) no-repeat;
			width: 20px;
			height: 5px;
			background-size: 20px 5px;
			position: absolute;
			top: 50%;
			-webkit-transform: translate(0,-50%);
			transform: translate(0,-50%);
			right: 15px;
		}
	}
}
