.heading-index {
	text-align: center;
	line-height: 1.2;
	margin-bottom: 64px;
    font-family: $title-font-family;

	@include media-xs {
		margin-bottom: 32px;
	}

	span {
		display: block;
		@include fs(34,20);
		font-weight: 600;
		margin-bottom: 12px;
	}

	small {
		display: block;
		font-weight: normal;
		@include fs(14,13);
		font-family: $sub-font-family;
		color: $sub-font-color;
	}
}

.heading-l {
	text-align: center;
	@include fs(34,20);
	font-weight: 500;
	margin: 0 0 55px;
    font-family: $title-font-family;

	@include media-xs {
		margin-bottom: 40px;
	}

	&::after {
		content: "";
		display: block;
		width: 60px;
		height: 2px;
		background: $base-color;
		margin: 14px auto 0;

		@include media-xs {
			width: 30px;
			margin-top: 8px;
		}

	}
}

.heading-m {
	@include fs(18,15);
	padding-bottom: 15px;
	border-bottom: 1px solid $base-border-color;
	margin: 0 0 20px;
    font-family: $title-font-family;

	@include media-xs {
		margin-bottom: 15px;
		padding-bottom: 12px;
	}
}

h3,.h3,.heading-s {
font-family: $title-font-family;
}

h4,.h4,.heading-ss {
font-family: $title-font-family;
}

h5,.h5,.heading-xs {
font-family: $title-font-family;
}

h6,.h6,.heading-sss {
font-family: $title-font-family;
}
