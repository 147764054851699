table {
	border-collapse: collapse;
	width: 100%;
	margin: 0 0 32px;
	table-layout: auto;
	border: 1px solid $base-font-color;

	th,td {
		border: 1px solid $base-font-color;
		text-align: left;
		padding: 12px 20px;

		@include media-xs {
			padding: 12px 10px;
		}

		&:last-child {
			border-right: none;
		}
	}

	th {
		background: $base-font-color;
		color: #fff;
		border-right-color: #fff;
		font-weight: normal;
	}
}


.table--xs-block {

	th,td {
		@include media-xs {
			display: block;
			margin-top: -1px;
			width: 100% !important; // stylelint-disable-line declaration-no-important
		}
	}
}

.table--xs-scroll {
	@include media-xs {
		overflow: scroll;

		table {
			min-width: 600px;
		}
	}
}

.table {
	border: none;
	margin: 0 0 70px;

	@include media-xs {
		margin-bottom: 55px;
	}

	th,td {
		border: none;
		vertical-align: top;
	}

	th {
		background: none;
		border-top: 1px solid $base-color;
		color: $base-color;
		padding: 15px 0;

		@include media-xs {
			padding: 10px 0;
		}
	}

	td {
		border-top: 1px solid $base-border-color;
		padding: 15px 25px;

		@include media-xs {
			padding: 10px 10px;
		}
	}
}
