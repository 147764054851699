.news-sort {
	margin-bottom: 96px;
	width: 220px;
	position: relative;
	border: 1px solid $base-font-color;
	background: #fff;

	@include media-xs {
		width: 100%;
		margin-bottom: 40px;
	}

	&__label {
		position: absolute;
		width: 100%;
		z-index: 1;
		height: 100%;
		font-family: $sub-font-family;

		&::after {
			content: "";
			display: block;
			background: url("../img/icon/icon-select.svg") no-repeat;
			@include bgs(10px,8px);
			@include centeringElements(false,true);
			right: 20px;

		}

		span {
			@include centeringElements;
			display: inline-block;
		}
	}

	select {
		appearance: none;
		border-radius: 0;
		border: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		background: none transparent;
		border: 1px solid $base-font-color;
		text-align: center;
		display: block;
		padding: 10px;
		opacity: 0;
		position: relative;
		z-index: 2;
		cursor: pointer;
	}

	option {
		display: block;
		text-align: center;
	}

	&::-ms-expand {
		display: none;
	}
}
