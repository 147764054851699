.global-header {
	padding: 20px 30px;
	margin: 0 0 20px;
	left: 0;
	z-index: 100;
	width: 100%;
	background: transparent;
	position: absolute;
	opacity: 1;
	min-width: $base-width + 30px;

	@include media-xs {
		padding: 20px;
		min-width: 320px;
	}

	&--scroll-m {
		transform: translate(0,-100px);
		position: fixed;
		opacity: 0;
	}

	&--scroll {
		transition: .8s $easeInOutCubic;
		background: #fff;
		transform: translate(0,0);
		opacity: 1;
	}

	@include media-xs {
		margin: 0 0 10px;
	}

	&__body {
		&-upper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__contact {

	}

	&__site-name {
		margin: 0;
		font-size: 1rem;

		a {
			display: block;
			text-indent: -9999px;
			background: url("../img/shared/logo-w.svg");
			@include bgs(225px, 24px);

			@include media-xs {
				@include bgs(165px, 17px);
			}

			.global-header--scroll & {
				background-image: url("../img/shared/logo.svg");
			}
		}
	}

	&__xs-menu {
		position: absolute;
		right: 20px;
		top: 13px;
		z-index: 2220;

		> button {
			@include buttonReset;
			width: 32px;
			height: 32px;
			display: block;

			// stylelint-disable
			&.active {

				span:nth-of-type(1) {
					transform: translateY(0) rotate(-45deg);
				}

				span:nth-of-type(2) {
					left: 60%;
					opacity: 0;
				}

				span:nth-of-type(3) {
					transform: translateY(9px) rotate(45deg);
				}
			}
			// stylelint-enable
			span {
				background-color: #fff;
				box-sizing: border-box;
				display: inline-block;
				height: 1px;
				left: 4px;
				border-radius: 4px;
				position: absolute;
				transition: all .4s;
				width: 28px;

				.global-header--scroll & {
					background: $base-font-color;
				}

				&:nth-of-type(1) {
					top: 15px;
				}

				&:nth-of-type(2) {
					top: 24px;
				}

				&:nth-of-type(3) {
					top: 6px;
				}
			}
		}
	}
}
