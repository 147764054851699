.home-hero {
	background: url("../img/home/bg-hero.jpg") no-repeat center center;
	background-size: cover;
	position: relative;
	min-height: 100vh;
	overflow: hidden;

	@include media-xs {
		background-image: url("../img/home/bg-hero-xs.jpg");
	}

	&__heading {
		@include fs(58,26);
		font-weight: 100;
		color: #fff;
		letter-spacing: .05em;
		display: inline-block;
		position: relative;
		padding: 40px 50px;

		@include media-xs {
			min-width: calc(9.8em + 50px);
			padding: 20px 25px;
		}

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background: #fff;
			left: 0;
			top: 0;
			transform: scale(0, 1);
			transform-origin: left center;

			.body-loaded & {
				animation: hover-in .5s forwards alternate, hover-out .5s .5s forwards alternate;
			}
		}
	}

	&__heading-inner {
		opacity: 0;
		transition: 0s;
		transition-delay: .5s;
        font-family: $title-font-family;

		.body-loaded & {
			opacity: 1;
		}

		&::before {
			content: "";
			display: block;
			width: 60px;
			height: 74px;
			border-top: 1px solid #fff;
			border-left: 1px solid #fff;
			position: absolute;
			left: 0;
				top: 0;
			opacity: 0;
			transition: 0s;
			transition-delay: .5s;

			.body-loaded & {
				opacity: 1;
			}

			@include media-xs {
				width: 30px;
				height: 37px;
			}
		}

		&::after {
			content: "";
			display: block;
			width: 60px;
			height: 74px;
			border-bottom: 1px solid #fff;
			border-right: 1px solid #fff;
			position: absolute;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: 0s;
			transition-delay: .5s;

			.body-loaded & {
				opacity: 1;
			}

			@include media-xs {
				width: 30px;
				height: 37px;
			}
		}
	}

	&__element {
		@include centeringElements;
		width: 100%;
		text-align: center;
	}

	&__scroll {
		text-align: center;
		position: absolute;
		bottom: 46px;
		width: 100%;
		@include fs(14,12);
		letter-spacing: .04em;

		@include media-xs {
			font-size: 1.1rem;
			bottom: 36px;
		}

		&::after {
			content: "";
			display: block;
			height: 40px;
			width: 1px;
			background: rgba(#fff,.5);
			position: absolute;
			left: 50%;
			top: calc(100% + 6px);
			transform-style: preserve-3d;
			animation: scrollAfter 2.2s ease 0s infinite;
			z-index: 1;

			@include media-xs {
				height: 32px;
				top: calc(100% + 6px);
				animation: scrollAfterXs 2.2s ease 0s infinite;
			}
		}

		a {
			color: rgba(#fff,.5);
			text-decoration: none;
			font-weight: normal;
		}
	}

	&__button {
		text-align: center;
		position: relative;
		width: 220px;
		margin: 60px auto 0;

		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background: #fff;
			left: 0;
			top: 0;
			transform: scale(0, 1);
			transform-origin: left center;

			.body-loaded & {
				animation: hover-in .5s forwards alternate, hover-out .5s .5s forwards alternate;
			}
		}

		@include media-xs {
			margin: 70px auto 0;
			width: 180px;
		}

		a {
			text-decoration: none;
			color: #fff;
			display: inline-block;
			@include fs(14,14);
			font-weight: 400;
			letter-spacing: .05em;
			width: 220px;
			text-align: center;
			position: relative;
			padding: 9px;
			border: 1px solid #fff;
			transition-delay: .1s;
			opacity: 0;
			transition: 0s;
			transition-delay: .5s;

			.body-loaded & {
				opacity: 1;
			}

			@include media-xs {
				width: 180px;
			}

			&::before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: -1;
				content: '';
				background: #fff;
				transform: scale(0, 1);
				opacity: .6;
			}

			span {
				display: block;
				position: relative;

				&::after {
					content: "";
					display: block;
					background: url("../img/icon/icon-ar-right.svg") no-repeat;
					@include bgs(26px,13px);
					@include centeringElements(false,true);
					right: 5px;

					@include media-xs {
						right: 0;
						@include bgs(20px,5px);
					}
				}

			}

			&:hover {
				@include media-sm {
					&::before {
						animation: hover-in .3s forwards alternate, hover-out .3s .3s forwards alternate;
					}
				}
			}
		}
	}
}


@keyframes scrollAfter {
	0% { height:  0px; transform: translate(0,  0px); }
	30% { height: 40px; transform: translate(0,  0px); }
	60% { height: 40px; transform: translate(0, 40px); }
	100% { height: 40px; transform: translate(0, 40px); }
}

@keyframes scrollAfterXs {
	0% { height:  0px; transform: translate(0,  0px); }
	30% { height: 32px; transform: translate(0,  0px); }
	60% { height: 32px; transform: translate(0, 32px); }
	100% { height: 32px; transform: translate(0, 32px); }
}

@keyframes hover-in {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes hover-out {
  0% {
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
}
