.newgraduate-section {
	margin-bottom: 80px;

	@include media-xs {
		padding: 0 20px;
		margin-bottom: 55px;
	}


	&__inner {
		@include media-sm {
			width: 960px;
			margin: 0 auto;
		}
	}
}
.newgraduate-head{
    background: #fafafa;
    margin-top: -70px;
    padding-top: 76px;
    padding-bottom: 80px;
    margin-bottom: 70px;
    @include media-xs {
        margin-top: -30px;
        padding: 35px 20px 50px;
        margin-bottom: 50px;
	}
    &-step{
        width: 960px;
        margin: auto;
        @include media-xs {
            width: 100%;
        }
        ul{
            display: flex;
            @include media-xs {
            display: block;
            }
            li{
                width: 198px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                @include media-xs {
                    width: 100%;
                }
                >div{
                    height: 168px;
                    width: 168px;
                    display: flex;
                    align-items: center;
                    background: #ccc;
                    justify-content: center;
                    flex-wrap: wrap;
                    font-size: 1.8rem;
                    color: #fff;
                    span{
                        display: block;
                        text-align: center;
                        small{
                            display: block;
                            font-size: 1.2rem;
                        }
                    }
                    @include media-xs {
                        height: auto;
                        padding: 8px;
                        width: 100%;
                        span{
                            font-size: 1.4rem;
                            small{
                                display: inline;
                            }
                        }
                    }
                }
                &::after{
                    content: "";
                    display: flex;
                    width: 30px;
                    height: 30px;
                    background: url(/img/shared/arrow-r-grey.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px 21px;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                }
                @include media-xs {
                    &::after{
                    content: "";
                    display: flex;
                    width: 100%;
                    height: 28px;
                    background: url(/img/shared/arrow-r-grey.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 12px 21px;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                        transform: rotate(90deg);
                    
                    }
                    }
            }
            li:nth-child(1){
                >div{background: #0083b3;}
            }
            li:nth-child(2){
                >div{background: #006fad;}
            }
            li:nth-child(3){
                >div{background: #005ba6;}
            }
            li:nth-child(4){
                >div{background: #00479f;}
            }
            li:last-child{
                width: 168px;                
                &::after{
                    content: none;
                }
                >div{background: #003399;}
                @include media-xs {
                    width: 100%;
                }
            }
        }
    }
    
}


.newgraduate-info {

	@include media-xs {
		margin-bottom: 0;
	}

    .newgraduate-table{
        th{
            width: 150px;
            @include media-xs {
            width: 90px;
            }
        }
    }
}
.newgraduate-qanda-block{
    &__list{
        border-bottom: 1px solid #dadada;
        dt,dd{
            padding: 22px 25px;
            margin: 0px;
            display: flex;
            justify-content: flex-start;
            @include media-xs {
                padding: 12px 12px;
            }
            
        }
        dt{
            border-top: 1px solid #dadada;
            background: #f2f5fa;
            cursor: pointer;
            h3{
                display: flex;
                width: calc(100% - 30px);
                padding-left: 15px;
                font-size: 2.0rem;
                line-height: 2.8rem;
                padding: 0px;
                margin: 0px;
                color: $base-color;
                @include media-xs {
                font-size: 1.4rem;
                line-height: 1.8rem;
                }
            }
            >div{
                display: flex;
                width: calc(100% - 30px);
                padding-left: 15px;
                justify-content: space-between;
                align-items: center;
                &::after{
                    content: "";
                    width: 21px;
                    height: 21px;
                    background:url(/img/shared/arrow-down-bc.svg) ;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    transition: 0.2s;
                }
                &.-active{
                    &::after{
                    content: "";
                    width: 21px;
                    height: 21px;
                    background:url(/img/shared/arrow-down-bc.svg) ;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    transform: rotate(180deg);
                    }
                }
               @include media-xs {
                padding-left: 8px;
                   &::after{
                    content: "";
                    width: 10px;
                    height: 10px;
                    background:url(/img/shared/arrow-down-bc.svg) ;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    transition: 0.2s;
                }
                &.-active{
                    &::after{
                    content: "";
                    width: 10px;
                    height: 10px;
                    background:url(/img/shared/arrow-down-bc.svg) ;
                    background-position: center center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    transform: rotate(180deg);
                    }
                }
                } 
            }
        }
        dd{
            display: none;
            >div{
                display: flex;
                justify-content: flex-start;
                >div{
                    display: flex;
                    
                width: calc(100% - 30px);
                padding-left: 15px;
                p{
                    margin: 0px;
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                    @include media-xs {
                        padding-left: 8px;
                        
                    }
                }
                
            }
            
        }
        .icon-q,.icon-a{
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            border: 1px solid $base-color;
            font-family:$sub-font-family;
            font-size: 2.0rem;
            text-indent: 4px;
            @include media-xs {
                font-size: 1.4rem;
                text-indent: 0px;
                width: 20px;
                height: 20px;
            }
        }
        .icon-q{
            background: $base-color;
            color:#fff;
        }
        .icon-a{
            color: $base-color;
            
        }
        
    }
    
    
}
.newgraduate-button-block{
    text-align: center;
    p{
        max-width:600px;
        text-align: right;
        margin: auto;
        font-size: 1.4rem;
        margin-top: 10px;
    }
    @include media-xs {
        margin-top: -40px;
        padding: 20px;
        margin-bottom: -30px;
            }
}

.recruit-button {
    &--large.-blank{
        position: relative;
        span:after {
			content: "";
			display: inline-block;
			background: url(../img/icon/icon-blank.png) no-repeat;
			width: 14px;
			height: 14px;
			background-size: 14px 13px;
			position: relative;
			top: 0%;
			right: 0px;
            vertical-align: text-bottom;
		}
        }
	}
