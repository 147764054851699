.nav-global {

	@include media-xs {
		position: absolute;
		z-index: 10;
		width: 100%;
		display: none;
		top: 56px;
		left: 0;
		background: rgba(#000,.7);
		right: 0;
		bottom: 0;
		height: calc(100vh - 56px);
		overflow: scroll;
	}

	&__body {
		@include media-xs {
			padding-bottom: 40px;
			background: #111;
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include media-xs {
			display: block;
		}
	}

	&__item {
		position: relative;
		margin-left: 40px;

		@include media-xs {
			margin: 0;
		}

		&--s {
			@include media-sm {
				margin-left: 15px;
			}
		}

		&--s-first {
			@include media-sm {
				margin-left: 40px;
			}
		}

		&--contact {
			@include media-xs {
				margin-top: 40px;
			}
		}
	}

	&__links {
		display: block;
		padding: 10px 5px;
		color: #fff;
		text-decoration: none;
		position: relative;
		font-weight: bold;
		letter-spacing: .03em;

		@include media-xs {
			font-weight: normal;
			padding: 12px 20px;
			border-bottom: 1px solid #414141;
			position: relative;
			&::after {
				content: "";
				display: block;
				background: url("../img/icon/icon-ar-links.svg") no-repeat;
				@include bgs(8px,12px);
				position: absolute;
				right: 20px;
				top: 50%;
				margin-top: -5px;
				transition: .3s;
			}

		}

		&--trigger {
			@include media-xs {
				&::after {
					background-image: url("../img/icon/ico-ar-down.svg");
					@include bgs(12px,8px);
					right: 18px;
				}

				&.active::after {
					transform: rotate(180deg);
				}
			}
		}

		&--contact {
			@include media-xs {
				width: 200px;
				margin: 0 auto;
				border: 1px solid #fff;
				text-align: center;
				font-size: 1.2rem;

				&::after {
					content: "";
					display: block;
					background: url("../img/icon/icon-ar-right.svg") no-repeat;
					@include bgs(26px,13px);
					@include centeringElements(false,true);
					right: 5px;

					@include media-xs {
						right: 8px;
						@include bgs(20px,5px);
						margin-top: -2px;
					}
				}
			}
		}

		.global-header--scroll & {
			@include media-sm {
				color: $base-font-color;
			}
		}

		span {
			position: relative;
			display: inline-block;
			text-decoration: none;

			&::after {
				position: absolute;
				bottom: -8px;
				left: 15%;
				content: '';
				width: 70%;
				height: 2px;
				background: #fff;
				transform: scale(0, 1);
				transform-origin: right top;
				transition: transform .3s;

				.global-header--scroll & {
					background-color: $base-font-color;
				}
			}
		}

		@include media-sm {
			&:hover span::after {
				transform-origin: left top;
				transform: scale(1, 1);
			}
		}

		&--s {
			@include media-sm {
				@include fs(12,14);
			}
		}
	}

	&__child {
		@include media-sm {
			width: 340px;
			background: #000;
			@include centeringElements(true,false);
			top: calc(100% + 20px);
			padding: 28px 30px;
			display: none;
			box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.13);
		}

		@include media-xs {
			position: relative;
			z-index: 1;
			margin-top: -1px;
			display: none;
		}
	}

	&__child-item {
		margin: 10px 0;

		@include media-xs {
			margin: 0;
			border-bottom: 1px solid #4e4e4e;
		}
	}


	&__child-links {
		text-decoration: none;
		color: #fff;
		font-weight: 400;
		@include fs(14,14);
		display: block;
		position: relative;

		@include media-xs {
			padding: 12px 40px;
			background: #222;
		}

		&::after {
			content: "";
			display: block;
			background: url("../img/icon/icon-ar-links.svg") no-repeat;
			@include bgs(6px,10px);
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -5px;
			opacity: .5;
			transition: .3s;

			@include media-xs {
				@include bgs(8px,12px);
				right: 20px;
			}
		}

		&:hover::after {
			@include media-sm {
				transform: translate(5px, 0);
			}
		}
	}
}
