.news-article {
	@include media-sm {
		width: 960px;
		margin: 0 auto;
	}

	&__header {
		margin-bottom: 40px;
		border-bottom: 1px solid $base-border-color;
		padding-bottom: 30px;

		@include media-xs {
			padding-bottom: 20px;
		}

		time {
			font-family: $sub-font-family;
			color: $sub-font-color;
			text-align: right;
			display: block;
		}
	}

	&__footer {
		text-align: center;
	}

	&__heading {
		@include fs(40,25);
		line-height: 1.4;
		margin: 0 0 16px;
		font-weight: 500;
	}
	&__body {
		margin-bottom: 45px;

		a {
            text-decoration: underline;
			&:hover {     
            text-decoration: none;
			}
		}

		a[target="_blank"] {
			&::after {
				content: "";
				display: inline-block;
				background: url("../img/icon/icon-external.png") no-repeat;
				@include bgs(14px,13px);
				vertical-align: middle;
				margin-left: 8px;
			}
		}

		p {
			margin: 0 0 24px;
			line-height: 1.8;
		}

		h1 {
			font-weight: 500;
			@include fs(40,24);
			margin: 0 0 12px;
			line-height: 1.4;
		}

		h2 {
			font-weight: 500;
			@include fs(32,21);
			margin: 0 0 12px;
			line-height: 1.5;
		}

		h3 {
			font-weight: 500;
			@include fs(28,19);
			margin: 0 0 12px;
		}

		h4 {
			font-weight: 500;
			@include fs(20,15);
			margin: 0 0 8px;
		}

		h5 {
			font-weight: 500;
			@include fs(16,14);
			margin: 0 0 10px;
		}
	}
}
 
/*wordpress用ネイティブCSS*/
strong { font-weight: bold; }
em { font-style: italic; }
blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
}
 
.aligncenter {
    display: block;
    margin: 0 auto;
}
.alignright { float: right; }
.alignleft { float: left; }
img[class*="wp-image-"],
img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
}
/* clearfix */
.clearfix {
    overflow: hidden;
    zoom: 1;
}
.clearfix:after {
    content: "";
    display: block;
    clear: both;
}