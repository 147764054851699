.form-lead {
	margin: 0 0 30px;

	p {
		margin: 0 0 1em;
	}
}

.form-table {
	border: none;
	table-layout: fixed;

	th,td {
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;

		@include media-xs {
			display: block;
			width: 100%;
		}
	}

	th {
		background: #f3f5fb;
		color: $base-font-color;
		width: 250px;
		padding: 15px 25px;
		border-left: none;
		border-right: none;
		font-weight: bold;

		@include media-xs {
			width: 100%;
			border-bottom: none;
			border-top: none;
			padding: 12px 20px;
		}
	}

	td {
		padding: 25px 0 25px 40px;
		border-left: none;

		@include media-xs {
			border-top: none;
			padding: 20px 0;

		}
	}

	&__th-wrap {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}

	&__req {
		display: inline-block;
		color: #fff;
		background: #b50a33;
		line-height: 1.2;
		@include fs(12,11);
		border-radius: 4px;
		padding: 3px 8px;

		@include media-xs {
			right: 20px;
		}
	}

	&__input-wrap {
		display: flex;
		margin: 0 -20px;

		@include media-xs {
			margin: 0 -5px;
		}
	}

	&__error {
		color: #b50b33;
		font-weight: 500;
		margin-bottom: 8px;
		background: url("../img/icon/icon-error.png") no-repeat left center;
		line-height: 1.2;
		background-size: 14px auto;
		padding-left: 20px;
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		display: inline-block;
		appearance: none;
		padding: 12px 20px;
		font-size: 1.6rem;
		line-height: 1.42857143;
		color: #555;
		background-color: #fff;
		background-image: none;
		border: 1px solid #ccc;
		border-radius: 0;
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

		@include media-xs {
			font-size: 1.4rem;
		}

		&:focus {
			outline: 0;
			border-color: $base-color;
		}

		&.error {
			border-color: #b40a33;
			background: #feeff3;
		}
	}

	textarea {
		display: block;
		height: 200px;
		width: 100%;

		@include media-xs {
			height: 150px;
		}
	}

	&__input {
		width: 100%;

		&--s {
			width: calc(50% - 40px);
			margin: 0 20px;

			@include media-xs {
				width: calc(50% - 10px);
				margin: 0 5px;
			}
		}

		&--m {
			width: calc(50% - 20px);

			@include media-xs {
				width: 100%;
			}
		}
	}

	&__caption-before {
		display: block;
		margin-bottom: 4px;
	}

	&__caption-after {
		display: block;
		text-align: right;
		margin-top: 18px;
	}

	&__postcode {
		display: inline-block;
		background: $base-color;
		color: #fff;
		text-decoration: none;
		padding: 2px 15px 3px;
		margin-left: 20px;
		transition: .3s;

		@include media-xs {
			margin-top: 10px;
			margin-left: auto;
			display: block;
			width: 140px;
		}

		span {
			display: block;
			padding-right: 20px;
			background: url("../img/icon/icon-external-w.png") no-repeat right center;
			background-size: 13px auto;
		}

		&:hover {
			opacity: .7;
		}
	}
}

.form-caption {
	background: #f9f9f9;
	padding: 40px;
	margin: 0 0 40px;

	@include media-xs {
		padding: 16px;
	}
}

.form-security {
	background: #fafafa;
	padding: 40px;

	@include media-xs {
		padding: 20px;
	}

	@include media-xs {
		padding: 16px;
	}

	&__body {
		display: flex;
		align-items: center;

		p {
			margin: 0 0 0 24px;
			@include fs(14,12);
			line-height: 1.6;

			@include media-xs {
				margin-left: 16px;
			}
		}
	}

	&__image {
		img {
			@include media-xs {
				width: 58px;
			}
		}
	}
}

.form-agreement {
	text-align: center;
	margin: 0 0 40px;

	p {
		margin: 0 0 16px;
	}

	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + label {
		padding-left: 30px;
		position: relative;
	}

	input[type="checkbox"] + label::before {
		content: "";
		display: block;
		position: absolute;
		top: 3px;
		left: 0;
		width: 19px;
		height: 19px;
		border: 2px solid #828282;
	}

	input[type="checkbox"]:checked + label::after {
		content: "";
		display: block;
		position: absolute;
		top: 4px;
		left: 7px;
		width: 6px;
		height: 13px;
		transform: rotate(45deg);
		border-bottom: 2px solid #585858;
		border-right: 2px solid #585858;
	}
}

.form-thanks {
	background: #fafafa;
	text-align: center;
	padding: 40px 0;

	@include media-xs {
		padding: 20px;
	}

	.button-block {
		margin-bottom: 0;
	}

	&__icon {
		margin-bottom: 40px;

		@include media-xs {
			margin-bottom: 20px;

			img {
				width: 48px;
			}
		}
	}

	&__head {
		font-weight: bold;
		@include fs(34,20);
	}

	p {
		margin: 34px 0 30px;

		@include media-xs {
			margin: 18px 0;
		}
	}
}
