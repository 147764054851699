.index-section {
	padding: 75px 0;

	@include media-xs {
		padding: 55px 0;
	}

	&__inner {
		@include defaultWidth;
		position: relative;

		@include media-xs {
			padding: 0 20px;
		}
	}

	&__button {
		position: absolute;
		right: 0;
		top: 20px;

		@include media-xs {
			position: static;
			text-align: center;
			margin-bottom: 40px;
		}

		a {
			color: $base-font-color;
			text-decoration: none;
			@include fs(14,12);

			@include media-xs {
				display: inline-block;
				border: 1px solid $base-font-color;
			}

			span {
				display: inline-block;
				position: relative;
				padding-left: 28px;

				@include media-xs {
					padding-right: 28px;
					padding: 6px 36px;
					min-width: 165px;
				}

				&::before {
					content: "";
					display: block;
					background: url("../img/icon/icon-ar-right-black.png") no-repeat;
					@include bgs(20px,5px);
					position: absolute;
					left: 0;
					top: 50%;
					margin-top: -3px;
					transition: .3s;

					@include media-xs {
						right: 10px;
						left: auto;
					}
				}
			}
		}

		&:hover {
			@include media-sm {
				span::before {
					transform: translate(5px, 0)
				}
			}
		}
	}
}

.index-service {
	background: #fafafa;

	&__body {
		display: flex;
		justify-content: space-between;

		@include media-xs {
			display: block;
		}
	}

	&__pict {
		margin-bottom: 24px;
		height: 250px;
		position: relative;

		@include media-xs {
			height: auto;
		}
	}

	&__ph {
		padding-top: 15px;

		&--s {
			padding-top: 0;
		}

		img {
			transition: .3s;

			@include media-xs {
				width: 220px;
			}
		}
	}

	&__logo {
		width: 100%;
		position: absolute;
		bottom: 0;

		@include media-sm {
			img {
				width: auto;
			}
		}

		@include media-xs {
			position: static;
			margin-top: 8px;
		}
	}

	&__element {
		width: 320px;
		margin-bottom: 30px;

		@include media-xs {
			width: 100%;
			margin-bottom: 60px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			display: block;
			color: $base-font-color;
			text-decoration: none;
			text-align: center;

			&:hover {
				@include media-sm {
					.index-service__ph img {
						transform: scale(1.08);
					}
				}
			}
		}

		p {
			margin: 0 0 32px;
			@include fs(15,13);

			@include media-xs {
				margin-bottom: 20px;
			}
		}
	}

	&__title {
		font-weight: bold;
		@include fs(18,16);
		margin-bottom: 14px;

		@include media-xs {
			margin-bottom: 12px;
		}
	}

	&__link {
		border-top: 1px solid $base-border-color;
		text-align: right;
		padding-top: 12px;

		span {
			display: inline-block;
			@include fs(12,12);
			position: relative;
			padding-right: 30px;

			&::after {
				content: "";
				display: block;
				background: url("../img/icon/icon-ar-right-black.png") no-repeat;
				@include bgs(20px,5px);
				position: absolute;
				right: 0;
				top: 50%;
				margin-top: -3px;
			}
		}
	}
}

.index-event {
	border-bottom: 1px solid #f2f2f2;
}

.index-information {
	padding-bottom: 140px;

	@include media-xs {
		padding-bottom: 70px;
	}

	&__body {
		width: 958px;
		margin: 0 auto;

		@include media-xs {
			width: 100%;
		}
	}

	&__element {
		padding: 40px 0;
		border-top: 1px solid $base-border-color;
		position: relative;
		display: flex;
		flex-wrap: wrap;

		@include media-xs {
			padding: 20px 0;
		}

		&::before {
			content: "";
			display: block;
			width: 100px;
			height: 1px;
			background: $base-color;
			position: absolute;
			top: -1px;
			left: 0;

			@include media-xs {
				width: 80px;
			}
		}

		time {
			width: 135px;
			font-weight: bold;
			color: $base-color;

			@include media-xs {
				width: 90px;
			}
		}
	}

	&__title {
		font-weight: bold;
		@include fs(18,14);
		margin-bottom: 12px;
		width: calc(100% - 135px);

		@include media-xs {
			width: calc(100% - 90px);
		}
	}

	&__detail {
		width: 100%;
		padding-left: 135px;

		@include media-xs {
			padding-left: 0;
		}
	}
}
