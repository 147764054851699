.breadcrumb {
	margin: 18px 0 90px;

	&__wrap {
		@include media-xs {
			position: relative;

			&::after {
				content: "";
				display: block;
				position: absolute;
				height: 100%;
				width: 30px;
				right: 0;
				background: linear-gradient(270deg, #fff, rgba(255,255,255,.0));
				top: 0;
			}
		}
	}

	@include media-xs {
		margin: 0 0 30px;
		padding: 10px 20px;
		overflow: auto;
		white-space: nowrap;
	}

	&__body {
		@include defaultWidth;
	}

	ol {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		line-height: 1;
		font-size: 1.2rem;

		&::after {
			content: "";
			display: inline-block;
			background: url("../img/icon/icon-breadcrumb.svg") no-repeat;
			margin: 0 14px;
			@include bgs(5px,8px);
			background-size: 5px auto;
		}

		&:last-child {
			&::after {
				display: none;
			}

			@include media-xs {
				padding-right: 20px;
			}
		}

		a {
			color: $base-font-color;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}
