.not-found {

	.global-header {
		top: 0;
		&--sub {
			.global-header__site-name a {
				background: url(../img/shared/logo.svg);
				width: 225px;
				height: 24px;
				background-size: 225px 24px;
			}

			.nav-global__links {
				color: #333;
			}

			@include media-xs {
				.nav-global__links {
					color: #fff;
				}
			}

			.global-header__xs-menu>button span {
				background: #333;
			}
		}
	}


	&-section {
		@include media-xs {
			padding: 100px 0;
		}

		&__inner {
			@include defaultWidth;
			text-align: center;
			padding: 200px 0;
			@include media-xs {
				padding: 0 20px;
			}
			&--s {
				@include media-sm {
					width: 960px;
				}
			}
		}

		h2 {
			@include fs(58,29);
		}

		h3 {
			@include fs(20,16);
		}

	}

	&__button {
		margin-top: 80px;

		@include media-xs {
			margin-top: 40px;
		}
	}
}
