.company-section {
	margin-bottom: 80px;

	@include media-xs {
		padding: 0 20px;
		margin-bottom: 55px;
	}


	&__inner {
		@include media-sm {
			width: 960px;
			margin: 0 auto;
		}
	}
}

.company-lead {

	&__head {
		width: 1200px;
		margin: 0 auto 40px;
		height: 438px;
		background: url("../img/company/bg-company-head.jpg") no-repeat center top;
		position: relative;

		@include media-xs {
			width: 100%;
			background: url("../img/company/bg-company-head-xs.jpg") no-repeat center top;
			background-size: cover;
			height: 0;
			padding-top: calc(310 / 670 * 100%);
			margin-bottom: 20px;
			overflow: hidden;
		}
	}

	&__head-element {
		font-family: $font-family-serif;
		font-weight: 400;
		line-height: 1.5;
		@include centeringElements(false,true);
		right: 200px;

		@include media-xs {
			width: 100%;
			right: auto;
			left: 50%;
			margin-left: -8%;
		}

		span {
			@include fs(34,14);
		}

		small {
			display: block;
			@include fs(18,11);
			margin-top: 30px;

			@include media-xs {
				margin-top: 12px;
			}
		}
	}

	&__text {
		p {
			margin: 0;
			line-height: 2.3;
		}
	}
}

.company-info {

	@include media-xs {
		margin-bottom: 0;
	}

	&__address {
		address {
			font-style: normal;
			margin-bottom: 18px;
			display: block;
		}
	}

	p {
		margin: 0 0 24px;

		@include media-sm {
			min-height: 11em;
		}
	}

	&__map {
		iframe {
			width: 100%;
			height: 280px;

			@include media-xs {
				height: 200px;
			}
		}

		@include media-xs {
			margin-bottom: 50px;
		}
	}
}

.company-service {
	background: #fafafa;
	padding: 70px 0;

	@include media-xs {
		padding: 50px 20px;
	}

	p {
		width: 960px;
		margin: 0 auto 45px;

		@include media-xs {
			width: 100%;
		}
	}

	&__inner {
		width: 1200px;
		margin: 0 auto;

		@include media-xs {
			width: 100%;
		}
	}

	.index-service__element {
		text-align: center;
		width: 373px;

		@include media-xs {
			width: 100%;
		}
	}

	.index-service__title {
		@include media-xs {
			font-size: 1.5rem;
		}
	}

	.index-service__pict {
		@include media-xs {
			margin-bottom: 20px;
		}
	}

	.button {
		width: 220px;
		margin: 0 auto;

		@include media-xs {
			width: 100%;
		}
	}

	ul {
		text-align: left;
		margin: 0 0 30px;

		@include media-sm {
			min-height: 3.5em;
			display: inline-block;
		}

		@include media-xs {
			margin: 0 0 15px;
		}

		li {
			position: relative;
			padding-left: 10px;
			&::before {
				content: "";
				display: block;
				width: 4px;
				height: 4px;
				background: $base-font-color;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: .85em;
			}
		}
	}
}

.company-history {
	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	dt {
		margin: 0 0 8px;
		max-width: 6.5em;
	}

	dd {
		margin: 0 0 8px;
		width: calc(100% - 6.5em);
	}
}

.company-quality {
	background: #fafafa;
	padding: 70px 0 120px;
	margin: 0 0 -70px;

	@include media-xs {
		padding: 55px 20px;
	}

	&__inner {
		width: 1200px;
		margin: 0 auto;

		@include media-xs {
			width: 100%;
		}
	}

	&__ph {
		margin-bottom: 26px;

		@include media-xs {
			margin-bottom: 14px;
		}
	}

	&__head {
		font-weight: bold;
		@include fs(18,15);
		margin:  0 0 12px;

		@include media-xs {
			margin-bottom: 6px;
		}
	}

	&__element {
		margin-bottom: 32px;
	}

	p {
		margin: 0;
	}
}
