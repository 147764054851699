.image {
	margin-bottom: 20px;

	@include media-xs {
		margin-bottom: 20px;
	}

	img {
		width: 100%;
	}
}
