.title-page {
	text-align: center;
	color: #FFF;
	background: url("../img/shared/bg-title-page.jpg") no-repeat center center;
	background-size: cover;
	height: 460px;
	position: relative;
	margin: 0;

	&--inquiry {
		background-image: url("../img/inquiry/bg-title-page-inquiry.jpg");
	}
	&--company {
		background-image: url("../img/company/bg-title-page-company.jpg");
	}
    &--recruit-new {
		background-image: url("../img/recruit/bg-title-page-recruit-new.jpg");
	}

	@include media-xs {
		height: 175px;
	}

	&__inner {
		@include defaultWidth;

		@include media-xs {
			padding: 0 20px;
		}
	}

	&__heading {
		@include centeringElements(false,true);
		margin: 0;
		font-weight: 200;
		@include fs(58,26);
		text-shadow: 2px 2px 0 rgba(#000,.3);
        text-align: left;
        font-family: $title-font-family;

		@include media-xs {
			padding-top: 15px;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			line-height: 1.2;
		}

		small {
			@include fs(34,18);
			display: inline;
			vertical-align: baseline;
			margin-top: 5px;

			@include media-xs {
				display: block;
				margin-top: 0;
			}
		}
        small.title-page__heading-sub {
			display: block;
            @include media-sm {margin-top:-10px;
                margin-bottom: -20px;}
            @include media-xs {margin-top:0px;
                margin-bottom: -10px;}
		}
	}
}
