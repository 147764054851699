$base-color: #003399;
$sub-color: #DDD;
$accent-color: #F18260;

$headdingColor: #232932;
$headdingSmallColor: #5391bb;
$headdingBgColor: #459ec8;

$color-warning: #ffdb12;
$color-danger: #e90000;

$selection-background-color: #0AC;

//font
$base-font-color: #333;
$sub-font-color: #808080;
$accent-font-color: #fff;
$caption-font-color: #999;
$link-font-color: #0080AC;
$footer-font-color: #A6A6A7;

//border
$base-border-color: #dadada;
