.page-sub {
	margin-bottom: 70px;

	&__inner {
		@include defaultWidth;

		@include media-xs {
			padding: 0 20px;
		}

		&--s {
			@include media-sm {
				width: 960px;
			}
		}
	}
}
