.heading-md {
    text-align: center;
    @include fs(34,
    20);
    line-height: 1.5;
    margin-bottom: 78px;
    position: relative;
	font-family: $title-font-family;

    @include media-xs {
        margin-bottom: 52px;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: -15px;
        display: inline-block;
        width: 60px;
        height: 2px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #003399;
        border-radius: 2px;
    }
}
