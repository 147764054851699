$font-family-hiragino: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-yugo:  '游ゴシック', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-meiryo: 'メイリオ', Meiryo, 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', Helvetica, Arial, sans-serif;
$font-family-yumin: '游明朝体', 'Yu Mincho', YuMincho, 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', HiraMinProN-W3, HGS明朝E, 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
$font-family-noto: "Noto Sans Japanese", "YuGothic", "YuGothicM", "メイリオ", "Meiryo", sans-serif;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500,600,700&subset=japanese');

$base-font-family: $font-family-meiryo;
$title-font-family: $font-family-noto;
$sub-font-family: 'Roboto', $font-family-noto;
$font-family-serif: 'Noto Serif JP', serif;


$root-font-size: 62.5%; // 10(base font size) / 16(Browser Default font size) = 0.625em * 100 = 62.5%
$base-font-size: 1.6rem; // 16pt base
$base-line-height: 1.8;
