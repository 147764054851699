body {
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin: 0;
	padding: 0;
	min-width: $base-width + 30px;
	@include media-xs {
		font-size: 1.4rem;
		min-width: 320px;
	}
}


img {
	vertical-align: top;
}


a {
	color: $link-font-color;
    text-decoration: underline;
    &:hover{
    text-decoration: none;
        
    }
}


ul,ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

@include media-xs {
	.hidden-xs {
		display: none;
	}
}

@include media-sm {
	.visible-xs {
		display: none;
	}
}

//-----------------------------------------------------------------------------------------

small {
	font-size: 80%;
}

strong,em {
	font-weight: bold;
}


//-----------------------------------------------------------------------------------------

@include media-sm {
	.inner {
		width: $base-width;
		margin: 0 auto;
	}
}

//-----------------------------------------------------------------------------------------

::selection {
	background:lighten( $base-color, 40% );
	color: #fff;
}

input::selection {
	color: $base-font-color;
}

textarea::selection {
	color: $base-font-color;
}

//margin
@for $i from -20 through 30 {
	.mt#{5*$i} {
		margin-top: #{5*$i}px!important;
	}
}

@for $i from 0 through 30 {
	.ml#{5*$i} {
		margin-left: #{5*$i}px!important;
	}
}
@for $i from 0 through 30 {
	.mr#{5*$i} {
		margin-right: #{5*$i}px!important;
	}
}
@for $i from 0 through 30 {
	.mb#{5*$i} {
		margin-bottom: #{5*$i}px!important;
	}
}

.row-0{
	margin-left:0px;
	margin-right:0px;
	> div{
		padding-right:0px;
		padding-left:0px;
	}
}
.row-10{
	margin-left:-5px;
	margin-right:-5px;
	> div{
		padding-right:5px;
		padding-left:5px;
	}
}
.row-20{
	margin-left:-10px;
	margin-right:-10px;
	> div{
		padding-right:10px;
		padding-left:10px;
	}
}
.row-30{
	margin-left:-15px;
	margin-right:-15px;
	> div{
		padding-right:15px;
		padding-left:15px;
	}
}
.row-40{
	margin-left:-20px;
	margin-right:-20px;
	> div{
		padding-right:20px;
		padding-left:20px;
	}
}
