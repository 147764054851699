.index-news {

	&__contents {

		@include media-xs {
			width: 100%;
		}

		&--page {
			display: block!important;

			@include media-xs {
				padding: 0!important;
			}
		}
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -20px;

		@include media-xs {
			margin: 0 -10px;
		}

		li {
			width: calc(100% / 4 - 40px);
			margin: 0 20px 0;

			.index-news__contents--sub & {
				@include media-sm {
					margin-bottom: 70px;
				}
			}

			@include media-xs {
				width: calc(100% / 2 - 20px);
				margin: 0 10px 30px;
			}

			a {
				color: $base-font-color;
				display: block;
				text-decoration: none;
				transition: .3s;

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}

			p {
				margin: 0;
			}
		}
	}

	&__header {
		display: flex;
		align-items: center;
		margin: 0 0 4px;

		@include media-xs {
			margin-bottom: 8px;
		}

		time {
			font-family: $sub-font-family;
			font-weight: bold;
			@include fs(14,11);
			display: block;
		}
	}

	&__title {
		@include media-xs {
			line-height: 1.5;
		}
	}

	&__cat {
		background: $sub-color;
		font-size: 1.2rem;
		color: #fff;
		display: inline-block;
		padding: 0 5px;
		margin-right: 10px;
		min-width: 80px;
		text-align: center;

		@include media-xs {
			font-size: 1rem;
			min-width: 50px;
			margin-right: 5px;
		}

		&--seminar {
			background: #0083b2;
		}

		&--event {
			background: #32b200;
		}
	}

	&__ph {
		width: 100%;
		margin: 0 0 20px;
		border-radius: 5px;
		overflow: hidden;
		border: 1px solid #DADADA;

		@include media-xs {
			margin-bottom: 12px;
			border-radius: 3px;
		}

		img {
			width: 100%;
			height: auto;
			transition: .25s $easeInOutQuad;
			transform-origin: center;
		}

		&--event {
			border: none;
		}
	}
}
