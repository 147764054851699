.policy {

	.global-header {
		top: 0;
			.global-header__site-name a {
				background: url(../img/shared/logo.svg);
				width: 225px;
				height: 24px;
				background-size: 225px 24px;
			}

			.nav-global__links {
				color: #333;
			}
			@include media-xs {
				.nav-global__links {
					color: #fff;
				}
			}
			.global-header__xs-menu>button span {
				background: #333;
			}
	}

	.page-sub {
		margin-bottom: 0;
	}

	.title-sub {
		text-align: center;
		color: #333;
		background: #fafafa;
		height: 200px;
		position: relative;
		margin: 88px 0 0;

		@include media-xs {
			height: 110px;
			margin: 64px 0 0;
		}

		&__inner {
			@include defaultWidth;

			@include media-xs {
				padding: 0 20px;
			}
		}

		&__heading {
			@include centeringElements(false,true);
			margin: 0;
			font-weight: 200;
			@include fs(58,26);

			@include media-xs {
			}

			span {
				line-height: 1.2;
			}

			small {
				@include fs(34,18);
				display: inline;
				vertical-align: baseline;
				margin-top: 5px;

				@include media-xs {
					display: block;
					margin-top: 0;
				}
			}
		}
	}

	&-section {
		@include media-xs {
			padding: 20px 0;
		}

		&__inner {
			@include defaultWidth;
			padding: 0 0 80px;
			@include media-xs {
				padding: 0 20px;
				margin-bottom: 80px;
			}
			&--s {
				@include media-sm {
					width: 960px;
				}
			}
		}
		&--gray {
			background: #fafafa;
		}
	}

	.heading-md {
		&--policy {
			&:before {
				background-color: #003399;
			}
		}
	}

	.policy-content {
		background: #fafafa;

		&__body {

			@include media-xs {
				display: block;
			}
		}

		&__ph {
			text-align: center;

			img {

			}
		}

		&__ar {
			text-align: right;
		}

		&__element {
			margin-bottom: 36px;
		}

		&__text {

			&--indent{
				text-indent: -2rem;
				padding-left: 2rem;
			}

			&-pl2 {
				padding-left: 2rem;

				li {
					margin-bottom: 10px;
				}
			}

			b {
				display: block;
			}
		}

		&__title {
		}

		&__link {
		}
	}

}
