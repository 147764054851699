.button-block {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 0 40px;

	%button-base {
		margin: 0 15px;
	}

	&__caption {
		display: block;
		width: 100%;
		text-align: center;
		margin-top: 15px;

		@include media-xs {
			margin-top: 8px;
		}
	}
}

@mixin buttonBase($color) {
	@extend %button-base;
	@include buttonReset;
	text-align: center;
	text-decoration: none;
	color: $color;
	display: inline-block;
	border: 1px solid $color;
	padding: 5px 10px;
	min-width: 200px;
	transition: .2s $linear;

	&:hover {
		background: $color;
		color: #FFF;
	}
}

@keyframes hover-in {
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  100% {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes hover-out {
  0% {
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
}




.button {
	@include buttonReset;
	text-decoration: none;
	color: $base-font-color;
	display: inline-block;
	@include fs(14,14);
	font-weight: 400;
	letter-spacing: .05em;
	min-width: 220px;
	text-align: center;
	position: relative;
	padding: 7px 6px;
	border: 1px solid $base-font-color;
	line-height: $base-line-height;
	font-weight: 500;

	@include media-xs {
		min-width: 170px;
	}

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		content: '';
		background: $base-font-color;
		transform: scale(0, 1);
		opacity: .6;
	}

	span {
		display: block;
		position: relative;

		&::after {
			content: "";
			display: block;
			background: url("../img/icon/icon-ar-right-black.png") no-repeat;
			@include bgs(20px,5px);
			@include centeringElements(false,true);
			right: 15px;

			@include media-xs {
				right: 3px;
				@include bgs(20px,5px);
			}
		}

	}

	&:hover {
		@include media-sm {
			&::before {
				animation: hover-in .3s forwards alternate, hover-out .3s .3s forwards alternate;
			}
		}
	}

	&--submit {
		border: 0;
		background: $base-color;
		color: #fff;
		@include fs(20,15);
		transition: .3s;
		width: 300px;

		@include media-xs {
			width: 100%;
		}

		&::before {
			display: none;
		}

		span::after {
			background-image: url("../img/icon/icon-ar-right.svg");
			background-size: 20px auto;
		}

		&:hover {
			opacity: .7;
		}
	}

	&--disabled {
		background-color: #828282;
		pointer-events: none;
	}
    
    &--style01 {
		border: 0;
		color: #333;
		@include fs(16,14);
		transition: .3s;
		width: 100%;
        padding: 10px;
        border: 1px solid #333;
        font-weight: normal;
		@include media-xs {
			width: 100%;
            span{
                padding-right:8%;
                padding-left: 0%;
            }
		}
//
//		&::before {
//			display: none;
//		}

		span::after {
			background-image: url("../img/icon/icon-ar-right-black.svg");
			background-size: 20px auto;
		}

//		&:hover {
//            background: $base-color;
//            color: #fff;
//            border: 1px solid $base-color;
//            span::after {
//			background-image: url("../img/icon/icon-ar-right.svg");
//			background-size: 20px auto;
//		    }
//		}
	}
    
    
}


.button-em {
	@include buttonBase(red);
}
