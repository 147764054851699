.title-page {
    &--digitalbook {
        background-image: url("../img/products/digitalbook/bg-title-page-digitalbook.jpg");
    }

    &--graphic {
        background-image: url("../img/products/graphic/bg-title-page-graphic.jpg");
    }

    &--webmeishi {
        background-image: url("../img/products/webmeishi/bg-title-page-webmeishi.jpg");
    }
}

.title-page__heading--s {
    font-family: $title-font-family;
    @include fs(34,
    16);
}

.products {
    img {
        @include media-xs {
            width: 100%;
            height: auto;
        }
    }
}

.products-pc {
    @include media-xs {
        display: none;
    }
}

.products-sp {
    @include media-sm {
        display: none;
    }
}

.products-section {
    @include media-xs {
        padding: 55px 0 80px;
    }

    &__inner {
        @include defaultWidth;
        padding: 80px 0;

        @include media-xs {
            padding: 0 20px;
        }

        &--s {
            @include media-sm {
                width: 960px;
            }
        }
    }

    &--gray {
        background: #fafafa;
    }

    &--texture {
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        background: url("../img/products/bg-grid.png");
    }
}

.heading-md--digitalbook:before {
    background-color: #d6006f;
}

.heading-md--webmeishi:before {
    background-color: #023894;
}

.heading-md--graphic:before {
    background-color: #0083b3;
}

.products-button {
    width: 400px;

    @include media-xs {
        width: 80%;
    }

    padding: 16px 0;
    @include fs(16,
    14);
    margin: auto;

    span:after {
        right: 15px;
    }

    &--lg {
        width: 600px;

        @include media-xs {
            width: 100%;
        }

        padding: 24px 0;
        @include fs(20,
        14);
        margin: auto;
    }

    &--lg span:after {
        right: 20px;
    }
}

.products-button-block {
    text-align: center;
}

.disitalbook-button {
    color: #fff;
    border: none;
    background: #d6006f;

    span:after {
        background: url(../img/icon/icon-ar-right-white.png) no-repeat;
        background-size: 20px 5px;
    }
}

.webmeishi-button {
    color: #fff;
    border: none;
    background: #023894;

    span:after {
        background: url(../img/icon/icon-ar-right-white.png) no-repeat;
        background-size: 20px 5px;
    }
}

.graphic-button {
    color: #fff;
    border: none;
    background: #0083b3;

    span:after {
        background: url(../img/icon/icon-ar-right-white.png) no-repeat;
        background-size: 20px 5px;
    }
}



.meclib-lead {
    background: linear-gradient(to bottom right, #FFF, #f8f8f8);

    @include media-xs {
        background: linear-gradient(to top, #f8f8f8, #FFF);
    }

    &__inner {
        @include media-sm {
            margin-top: -72px;
        }

        @include media-xs {
            margin-top: -30px;
        }

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__text {
        width: 430px;

        @include media-xs {
            margin-bottom: 40px;
            width: 100%;
        }
    }

    &__logo {
        @include media-xs {
            max-width: 227px;
        }
    }

    &__pict {
        width: 484px;

        @include media-xs {
            width: 90%;
            max-width: 484px;
            margin: auto;
        }
    }
}

.meclib-movie {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.meclib-point {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
        width: 280px;
        margin-bottom: 40px;

        @include media-xs {
            width: 45%;
        }
    }

    &__icon {
        text-align: center;

        img {
            max-width: 150px;
        }
    }

    &__heading {
        color: #d6006f;
        text-align: center;
    }
}

.meclib-intro {
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__text {
        width: 540px;

        @include media-xs {
            margin-bottom: 40px;
            width: 100%;
        }
    }

    &__graph {
        width: 380px;
        margin: 0 0 40px 0;

        @include media-xs {
            max-width: 380px;
            margin: 0 auto 40px;
        }
    }

    &__box {
        background: #fff;
        border: solid 3px #dadada;
        padding: 16px 32px 32px;

        @include media-xs {
            padding: 16px;
        }
    }
}

.meclib-design {
    &__inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    &__text {
        width: 430px;

        @include media-xs {
            margin-bottom: 40px;
            width: 100%;
        }

        p {
            @include media-sm {
                margin-bottom: 34px;
            }
        }
    }

    &__pict {
        width: 484px;

        @include media-xs {
            width: 90%;
            max-width: 484px;
            margin: auto;
        }
    }

    &__title {
        color: #d6006f;
    }
}

.graphic-lead {
    @include media-sm {
        background-image: url(../img/products/graphic/bg-lead-01.jpg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-color: #fafafa;
        background-size: contain;
    }

    @include media-xs {
        background-image: url(../img/products/graphic/bg-lead-01-s.jpg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-color: #fafafa;
        background-size: cover;
    }

    &__inner {
        @include media-sm {
            margin-top: -72px;
        }

        @include media-xs {
            margin-top: -30px;
        }
    }

    &__text {
        width: 480px;

        @include media-xs {
            margin-bottom: 40px;
            width: 100%;
        }
    }
}

.graphic-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-xs {
        margin-bottom: -14px;
    }

    li {
        color: #0083b3;
        background: #e5f2f7;
        width: 225px;
        padding: 16px 0;
        text-align: center;
        margin-bottom: 24px;

        @include media-xs {
            width: 48%;
            margin-bottom: 14px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 225px;
        order: 1;
    }

    &::after {
        content: "";
        display: block;
        width: 225px;
    }
}

.graphic-point {
    &__inner {
        @include media-sm {
            display: flex;
            justify-content: space-between;
            margin-bottom: 80px;
        }

        &:not(:last-child) {
            @include media-xs {
                width: 100%;
                margin-bottom: 48px;
            }
        }
    }

    &__icon {
        margin-right: 1rem;
        max-width: 40px;
    }

    &__text {
        width: 480px;

        @include media-xs {
            width: 100%;
        }

        h3 {
            display: flex;
            align-items: center;
        }
    }

    &__pict {
        width: 420px;

        @include media-xs {
            width: 100%;
        }
    }
}

.graphic-step {
    img[src$=".svg"] {
        width: 218px;
        height: 54px;
    }

    li {
        background: #fafafa;
        border: 1px solid #f2f2f2;
        padding: 16px 32px;

        @include media-xs {
            padding: 8px 16px;
        }
    }

    &__item {
        @include media-sm {
            display: flex;
            align-items: center;
        }

        margin-bottom: 32px;
        position: relative;

        &:after {
            content: "";
            border-top: 20px solid #dadada;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            position: absolute;
            left: 50%;
            bottom: -28px;
            transform: translateY(0) translateX(-50%);
        }

        &:last-child:after {
            content: none;
        }
    }

    &__title {

        @include media-xs {
            max-width: 218px;
        }

        @include media-sm {
            padding-right: 32px;
            position: relative;

            &:after {
                content: "";
                width: 1px;
                height: 32px;
                display: inline-block;
                background: #000;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateX(0%);
                margin-left: 32px;
            }

            &--01 {
                &:after {
                    background: #0083b3;
                }
            }

            &--02 {
                &:after {
                    background: #0071ad;
                }
            }

            &--03 {
                &:after {
                    background: #0061a8;
                }
            }

            &--04 {
                &:after {
                    background: #0053a3;
                }
            }

            &--05 {
                &:after {
                    background: #00409e;
                }
            }

            &--06 {
                &:after {
                    background: #003399;
                }
            }
        }

    }

    &__text {
        @include media-sm {
            margin-left: 32px;
        }
    }
}

.graphic-topic {
    background: #fff;
    border: solid 3px #dadada;
    padding: 16px 32px 32px;

    @include media-sm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;
    }

    &__text {
        width: 540px;

        @include media-xs {
            width: 100%;
        }

        h3 {
            color: #0083b3;
        }

        p {
            @include media-sm {
                margin-bottom: 24px;
            }
        }
    }

    &__pict {
        width: 324px;

        @include media-xs {
            width: 100%;
            max-width: 324px;
            margin: 32px auto;
        }
    }
}

.graphic-catalog {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
        width: 373px;
        background: #fff;
        border: 1px solid #dadada;
        padding: 7px 32px 20px;
        margin-bottom: 32px;

        @include media-xs {
            width: 100%;
        }

        h3 {
            color: #0083b3;
        }
    }

    &__topic {
        width: 786px;
        background: #0083b3;
        height: 100%;
        padding: 16px 32px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 5px;

        p {
            @include media-sm {
                padding-left: 32px;
            }
        }
    }
}

.graphic-anuscript {
    background: #e5f2f7;
    padding: 32px;
    font-weight: bold;

    @include media-sm {
        display: flex;
        justify-content: space-between;
    }

    ul:not(:last-child) {
        @include media-xs {
            margin-bottom: 16px;
        }
    }
}

.graphic-delivery {
    @include media-sm {
        display: flex;
        justify-content: space-between;
    }

    li {
        width: 371px;

        @include media-xs {
            width: 100%;
        }

        &:last-child {
            @include media-xs {
                margin-bottom: -14px;
            }
        }

        &:not(:last-child) {
            @include media-xs {
                margin-bottom: 48px;
            }
        }
    }
}

.graphic-pmark {
    @include media-sm {
        display: flex;
        justify-content: space-between;
    }

    p {
        width: 770px;

        @include media-xs {
            width: 100%;
        }
    }

    &__pict {
        text-align: center;

        @include media-xs {
            margin-top: 40px;
        }

        img {
            max-width: 141px;
        }
    }
}




.webmeishi-lead {
    background: linear-gradient(to bottom right, #FFF, #f8f8f8);

    @include media-xs {
        background: linear-gradient(to top, #f8f8f8, #FFF);
    }

    &__inner {
        @include media-sm {
            margin-top: -72px;
        }

        @include media-xs {
            margin-top: -30px;
        }

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__text {
        width: 422px;

        @include media-xs {
            margin-bottom: 40px;
            width: 100%;
        }
    }

    &__logo {
        @include media-xs {
            max-width: 203px;
        }
    }

    &__pict {
        width: 538px;

        @include media-xs {
            width: 90%;
            max-width: 484px;
            margin: auto;
        }
    }
}

.webmeishi-step {
    @include media-sm {
        display: flex;
        justify-content: space-between;
    }

    li {
        width: 297px;

        @include media-xs {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: 40px;
        }

        position: relative;

        @include media-sm {
            &:not(:last-child):after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border-top: solid 2px #003399;
                border-right: solid 2px #003399;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                top: 50%;
                right: -22px;
            }
        }

        @include media-xs {
            &:not(:last-child):after {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border-bottom: solid 2px #003399;
                border-right: solid 2px #003399;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                right: 50%;
                bottom: -24px;
            }
        }
    }

    h3 {
        margin: 0;
    }

    &__title {
        background: #003399;
        color: #fff;
        padding: 0 24px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @include media-xs {
            line-height: 1.5;
            padding: 16px;
        }

        &-01,
        &-02,
        &-03 {
            display: flex;
            align-items: center;
        }

        &-01:before {
            content: "1";
            font-family: $sub-font-family;
            @include fs(60,
            30);
            font-style: italic;
            padding-right: 24px;
        }

        &-02:before {
            content: "2";
            font-family: $sub-font-family;
            @include fs(60,
            30);
            font-style: italic;
            padding-right: 24px;
        }

        &-03:before {
            content: "3";
            font-family: $sub-font-family;
            @include fs(60,
            30);
            font-style: italic;
            padding-right: 24px;
        }
    }

    &__pict {
        background: #e5f3f9;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 24px;

        @include media-xs {
            text-align: center;
        }

        img {
            @include media-xs {
                max-width: 249px;
            }
        }
    }
}

.webmeishi-point {
    border-radius: 10px;
    padding: 32px;

    @include media-xs {
        padding: 16px 24px;
    }

    &:not(:last-child) {
        margin-bottom: 40px;
    }

    background: url(../img/products/webmeishi/bg-point.png) left top no-repeat #fff;

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #003399;
        @include fs(28,
        18);
        line-height: 1.5;

        img {
            margin-right: 8px;

            @include media-xs {
                max-width: 37px;
            }
        }
    }

    &__list {
        width: 700px;
        margin: auto;

        @include media-xs {
            width: 100%;
        }
    }

    &__text {
        margin: 8px 0 24px;
    }

    &__subtitle {
        margin: 0;

        &:before {
            content: "";
            display: inline-block;
            background: url(../img/products/webmeishi/icon-check.svg);
            width: 18px;
            height: 15px;
            margin-right: 8px;
        }
    }
}

.webmeishi-meishilist {
    border: 3px solid #f0f1f0;
    padding: 32px;

    @include media-xs {
        padding: 16px;
    }
}
