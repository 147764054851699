.global-footer {
	background: #828282;
	position: relative;
	color: #fff;

	&__upper {
		@include defaultWidth;
		padding: 40px 0;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@include media-xs {
			padding: 32px 0;
			display: block;
		}
	}

	&__page-top {
		position: absolute;
		top: -60px;
		right: 0px;

		@include media-xs {
			right: 0;
			top: -44px;
		}
		a {
			display: block;
			width: 60px;
			height: 60px;
			background: #333 url("../img/shared/img-ar-pagetop.png") no-repeat center center;
			transition: .3s;

			@include media-xs {
				width: 44px;
				height: 44px;
			}

			&:hover {
				transform: translate(0, -5px);
			}
		}
	}

	&__sitemap {
		display: flex;

		@include media-xs {
			display: none;
		}
	}


	&__links {
		margin: 0;
		padding: 0;
		margin-right: 80px;
        font-family: $title-font-family;
        font-size: 1.4rem;
        font-weight: 700;

		@include media-xs {
			display: block;
			margin: 0 0 20px;
		}

		a {
				text-decoration: none;
				color: $base-font-color;
				font-weight: bold;

				@include media-xs {
					display: block;
					padding: 10px 15px;
					border-bottom: 1px solid #CCC;
					font-size: 1.3rem;
				}
		}

		&-element {
			margin-bottom: 12px;
			@include media-xs {
				margin-left: 0;
			}

			a {
				color: #fff;
				@include fs(14,12);
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__links-s {
		margin-top: 4px;
		&-element {
			a {
				color: rgba(#fff,.5);
				@include fs(12,12);
				font-weight: normal;
			}
		}
	}

	&__sns {
		display: flex;
		align-items: center;

		@include media-xs {
			justify-content: center;
		}
	}

	&__sns-element {
		margin-left: 8px;

		@include media-xs {
			margin: 0 6px;
		}

		&:last-child {
			margin-left: 24px;

			@include media-xs {
				margin-left: 14px;
			}
		}

		@include media-xs {
			> img {
				width: 42px;
			}
		}
	}

	&__sns-links {
		transition: .3s;
		display: block;
		width: 36px;
		height: 36px;
		border: 1px solid #fff;
		border-radius: 50%;
		text-align: center;
		position: relative;

		@include media-xs {
			width: 44px;
			height: 44px;
		}

		img {
			@include centeringElements;
		}


		&:hover {
			transform: translate(0,-4px);
		}
	}

	&__lower {
		border-top: 1px solid #A8A8A8;
		padding: 18px 0 14px;

		@include media-xs {
			padding: 12px 20px;
		}
	}

	&__lower-inner {
		@include defaultWidth;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-xs {
			display: block;
		}
	}

	&__logo {
		display: flex;
		align-items: center;

		@include media-xs {
			justify-content: space-between;
		}
	}

	&__logo-element {
		img {
			@include media-xs {
				width: 140px;
				height: auto;
			}
		}
	}

	&__logo-caption {
		@include fs(10,10);
		margin-left: 25px;

		@include media-xs {
			margin-left: 22px;
		}
	}

	&__copyright {
		@include fs(10,10);
		color: rgba(#fff,.5);
		line-height: 1;

		@include media-xs {
			margin-top: 12px;

		}
	}
}
